var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contents" },
    [
      _c("div", { staticClass: "opt" }, [
        _c(
          "div",
          { staticClass: "search" },
          [
            _c(
              "el-popover",
              {
                ref: "popover",
                staticClass: "searchPop",
                attrs: {
                  placement: "top",
                  title: "批量搜索",
                  trigger: "click",
                  width: "378",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "close", on: { click: _vm.closeSearchPanel } },
                  [_c("i", { staticClass: "el-icon-close" })]
                ),
                _c("base-muti-input", {
                  ref: "baseMutiInput",
                  model: {
                    value: _vm.referenceNumber,
                    callback: function ($$v) {
                      _vm.referenceNumber = $$v
                    },
                    expression: "referenceNumber",
                  },
                }),
                _c(
                  "div",
                  {
                    staticStyle: { "text-align": "right", margin: "16px 0 0" },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: { click: _vm.handleEmpty },
                      },
                      [_vm._v("清空")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.handleConfirm },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("el-input", {
              staticClass: "input-search",
              attrs: { value: _vm.searchVal, placeholder: "请输入提单号搜索" },
              on: { focus: _vm.handleFocus },
            }),
            _c(
              "div",
              { staticClass: "btn-search", on: { click: _vm.search } },
              [_c("i", { staticClass: "el-icon-search" })]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "permissson",
                rawName: "v-permissson",
                value: "add",
                expression: "'add'",
              },
            ],
            staticClass: "add flex_c_c",
            on: { click: _vm.add },
          },
          [
            _c("svg-icon", {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "add",
                  expression: "'add'",
                },
              ],
              attrs: { className: "icon", iconClass: "add" },
            }),
            _vm._v(" 新增 "),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                attrs: {
                  height: _vm.height,
                  tableData: _vm.tableData,
                  pageIndex: _vm.pager.pageIndex,
                  pageSize: _vm.pager.pageSize,
                  loading: _vm.loading,
                  isPager: true,
                },
                on: {
                  sortChange: _vm.sortChange,
                  handlePageChange: _vm.handlePageChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permissson",
                                  rawName: "v-permissson",
                                  value: "update",
                                  expression: "'update'",
                                },
                              ],
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(row.scope)
                                },
                              },
                            },
                            [_vm._v("修改")]
                          ),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permissson",
                                  rawName: "v-permissson",
                                  value: "delete",
                                  expression: "'delete'",
                                },
                              ],
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(row.scope)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  407959682
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("TaxAddDialog", {
        attrs: { dialogVisible: _vm.dialogVisible, editData: _vm.editData },
        on: { cancel: _vm.cancel, ok: _vm.ok },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }