<template>
  <div class="contents">
    <div class="opt">
      <div class="search">
        <el-popover
          ref="popover"
          placement="top"
          class="searchPop"
          title="批量搜索"
          trigger="click"
          width="378"
        >
          <div class="close" @click="closeSearchPanel">
            <i class="el-icon-close"></i>
          </div>
          <base-muti-input
            ref="baseMutiInput"
            v-model="referenceNumber"
          ></base-muti-input>
          <div style="text-align: right; margin: 16px 0 0">
            <el-button size="small" @click="handleEmpty">清空</el-button>
            <el-button
              size="small"
              type="primary"
              @click="handleConfirm"
            >确定</el-button
            >
          </div>
        </el-popover>
        <el-input
          :value="searchVal"
          placeholder="请输入提单号搜索"
          class="input-search"
          @focus="handleFocus"
        ></el-input>
        <div class="btn-search" @click="search">
          <i class="el-icon-search"></i>
        </div>
      </div>
      <div v-permissson="'add'" class="add flex_c_c" @click="add">
        <svg-icon
          v-permissson="'add'"
          className="icon"
          iconClass="add" /> 新增
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :tableData="tableData"
        :pageIndex="pager.pageIndex"
        :pageSize="pager.pageSize"
        :loading="loading"
        :isPager="true"
        @sortChange="sortChange"
        @handlePageChange="handlePageChange"
      >
        <template slot="opt" slot-scope="row">
          <el-button v-permissson="'update'" type="text" @click="handleEdit(row.scope)">修改</el-button>
          <el-button
            v-permissson="'delete'"
            type="text"
            @click="handleDelete(row.scope)"
          >删除</el-button
          >
        </template>
      </BaseTable>
    </div>
    <TaxAddDialog
      :dialogVisible="dialogVisible"
      :editData="editData"
      @cancel="cancel"
      @ok="ok"
    >
    </TaxAddDialog>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseMutiInput = () => import('@/components/base/BaseMutiInput')
import { getTaxRatePage, deleteTaxRate } from '@/api/basic'
const TaxAddDialog = () => import('@/components/dialog/TaxAddDialog')
import { myMixins } from '@/mixins'
export default {
  name: 'TaxRate',
  components: { BaseTable, BaseMutiInput, TaxAddDialog },
  mixins: [myMixins],
  data() {
    return {
      tableData: {
        fileds: [
          {
            filed: 'hsCode',
            label: '税号',
            width: '230',
            isSort: true
          },
          {
            filed: 'desEN',
            label: '英文描述',
            width: ''
          },
          {
            filed: 'desCH',
            label: '中文描述',
            width: ''
          },
          {
            filed: 'baseRate',
            label: '基础税率',
            width: '100',
            formatter: (row) =>
              row.baseRate ? `${row.baseRate}%` : ''
          },
          {
            filed: 'addRate',
            label: '加征税率',
            width: '100',
            formatter: (row) =>
              row.addRate ? `${row.addRate}%` : ''
          },
          {
            filed: 'batchNo',
            label: '批次号',
            width: '120'
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '140',
            formatter: (row) => {
              return row.createdOn.slice(0, 16)
            }
          },
          {
            filed: 'remark',
            label: '备注',
            width: ''
          },
          {
            filed: 'updatedOn',
            label: '修改时间',
            width: '140',
            formatter: (row) => {
              return row.createdOn.slice(0, 16)
            }
          },
          {
            filed: 'opt',
            label: '操作',
            width: '180',
            isCustom: true
          }
        ],
        columns: [],
        total: 0
      },
      loading: false,
      filterList: {},
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      sortable: {
        sortField: undefined,
        sortBy: undefined
      },
      referenceNumber: '',
      searchVal: '',
      dialogVisible: false,
      editData: {},
      height: 0
    }
  },
  created() {
    this.getList(this.pager)
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 43
  },
  methods: {
    async getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.sortable,
            hsCode: this.searchVal ? this.searchVal.split(';') : undefined
          }
        }
        const res = await getTaxRatePage(params)
        if (res.success) {
          this.loading = false
          this.tableData.columns = res.data.list
          this.tableData.total = res.data.total
        } else {
          this.loading = false
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    handleEdit(row) {
      this.editData = row
      this.dialogVisible = true
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        iconClass: 'warning',
        showClose: false,
        closeOnPressEscape: false,
        closeOnClickModal: false,
        center: true
      })
        .then(() => {
          deleteTaxRate({
            id: row.id
          }).then((res) => {
            if (res.success) {
              this.$message.success('删除成功')
              this.initList()
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        })
        .catch(() => {})
    },
    sortChange(params) {
      console.log('params', params)
      this.sortable.sortField = params.prop || undefined
      this.sortable.sortBy =
        params.order === undefined ? undefined : params.order
      this.getList(this.pager)
    },
    closeSearchPanel() {
      this.$refs.popover.doClose()
    },
    handleEmpty() {
      this.referenceNumber = ''
      this.searchVal = ''
      this.$refs.baseMutiInput.reset()
      this.$refs.popover.doClose()
      this.initList()
    },
    handleConfirm() {
      if (this.referenceNumber && this.referenceNumber !== '') {
        this.searchVal = this.referenceNumber.join(';')
        this.$refs.popover.doClose()
      }
    },
    handleFocus() {
      this.$refs.popover.doShow()
    },
    search() {
      this.initList()
    },
    add() {
      this.dialogVisible = true
    },
    ok() {
      this.cancel()
      this.initList()
    },
    initList() {
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = params
      this.getList(params)
    },
    cancel() {
      this.dialogVisible = false
      this.editData = {}
    },
    handlePageChange(pager) {
      this.pager = {
        ...pager
      }
      this.getList(pager)
    }
  }
}
</script>

<style lang="less" scoped>
.contents {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
}
.opt {
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
  .search {
    position: relative;
    display: flex;
    width: 200px;
    margin-right: 8px;
    .searchPop {
      /deep/ .el-popover {
        z-index: 9999;
        right: 0;
      }
      .close {
        position: absolute;
        top: 6px;
        right: 10px;
        cursor: pointer;
        font-size: 20px;
      }
    }
    .input-search {
      width: 166px;
      height: 30px !important;
      /deep/ .el-input__inner {
        height: 30px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .btn-search {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border: 1px solid #dbe4f2;
      border-left: 0;
      border-radius: 0 4px 4px 0;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
}
.add {
  width: 84px;
  height: 30px;
  background: #2395f3;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  svg {
    font-size: 20px;
  }
}
.data {
  width: 100%;
  height: calc(100% - 54px);
  z-index: 1;
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
  &:deep(.el-button) + .el-button {
    margin-left: 30px;
  }
}
</style>
